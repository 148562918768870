/*ace_scrollbar.css*/
.ace_editor>.ace_sb-v div, .ace_editor>.ace_sb-h div{
position: absolute;
background: rgba(128, 128, 128, 0.6);
-moz-box-sizing: border-box;
box-sizing: border-box;
border: 1px solid #bbb;
border-radius: 2px;
z-index: 8;
}
.ace_editor>.ace_sb-v, .ace_editor>.ace_sb-h {
position: absolute;
z-index: 6;
background: none;
overflow: hidden!important;
}
.ace_editor>.ace_sb-v {
z-index: 6;
right: 0;
top: 0;
width: 12px;
}
.ace_editor>.ace_sb-v div {
z-index: 8;
right: 0;
width: 100%;
}
.ace_editor>.ace_sb-h {
bottom: 0;
left: 0;
height: 12px;
}
.ace_editor>.ace_sb-h div {
bottom: 0;
height: 100%;
}
.ace_editor>.ace_sb_grabbed {
z-index: 8;
background: #000;
}
/*ace_editor.css*/
.ace_br1 {border-top-left-radius    : 3px;}
.ace_br2 {border-top-right-radius   : 3px;}
.ace_br3 {border-top-left-radius    : 3px; border-top-right-radius:    3px;}
.ace_br4 {border-bottom-right-radius: 3px;}
.ace_br5 {border-top-left-radius    : 3px; border-bottom-right-radius: 3px;}
.ace_br6 {border-top-right-radius   : 3px; border-bottom-right-radius: 3px;}
.ace_br7 {border-top-left-radius    : 3px; border-top-right-radius:    3px; border-bottom-right-radius: 3px;}
.ace_br8 {border-bottom-left-radius : 3px;}
.ace_br9 {border-top-left-radius    : 3px; border-bottom-left-radius:  3px;}
.ace_br10{border-top-right-radius   : 3px; border-bottom-left-radius:  3px;}
.ace_br11{border-top-left-radius    : 3px; border-top-right-radius:    3px; border-bottom-left-radius:  3px;}
.ace_br12{border-bottom-right-radius: 3px; border-bottom-left-radius:  3px;}
.ace_br13{border-top-left-radius    : 3px; border-bottom-right-radius: 3px; border-bottom-left-radius:  3px;}
.ace_br14{border-top-right-radius   : 3px; border-bottom-right-radius: 3px; border-bottom-left-radius:  3px;}
.ace_br15{border-top-left-radius    : 3px; border-top-right-radius:    3px; border-bottom-right-radius: 3px; border-bottom-left-radius: 3px;}
.ace_editor {
position: relative;
overflow: hidden;
padding: 0;
font: 12px/normal 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', 'Source Code Pro', 'source-code-pro', monospace;
direction: ltr;
text-align: left;
-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
forced-color-adjust: none;
}
.ace_scroller {
position: absolute;
overflow: hidden;
top: 0;
bottom: 0;
background-color: inherit;
-ms-user-select: none;
-moz-user-select: none;
-webkit-user-select: none;
user-select: none;
cursor: text;
}
.ace_content {
position: absolute;
box-sizing: border-box;
min-width: 100%;
contain: style size layout;
font-variant-ligatures: no-common-ligatures;
}
.ace_invisible {
font-variant-ligatures: none;
}
.ace_keyboard-focus:focus {
box-shadow: inset 0 0 0 2px #5E9ED6;
outline: none;
}
.ace_dragging .ace_scroller:before{
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
content: '';
background: rgba(250, 250, 250, 0.01);
z-index: 1000;
}
.ace_dragging.ace_dark .ace_scroller:before{
background: rgba(0, 0, 0, 0.01);
}
.ace_gutter {
position: absolute;
overflow : hidden;
width: auto;
top: 0;
bottom: 0;
left: 0;
cursor: default;
z-index: 4;
-ms-user-select: none;
-moz-user-select: none;
-webkit-user-select: none;
user-select: none;
contain: style size layout;
}
.ace_gutter-active-line {
position: absolute;
left: 0;
right: 0;
}
.ace_scroller.ace_scroll-left:after {
content: "";
position: absolute;
top: 0;
right: 0;
bottom: 0;
left: 0;
box-shadow: 17px 0 16px -16px rgba(0, 0, 0, 0.4) inset;
pointer-events: none;
}
.ace_gutter-cell, .ace_gutter-cell_svg-icons {
position: absolute;
top: 0;
left: 0;
right: 0;
padding-left: 19px;
padding-right: 6px;
background-repeat: no-repeat;
}
.ace_gutter-cell_svg-icons .ace_gutter_annotation {
margin-left: -14px;
float: left;
}
.ace_gutter-cell .ace_gutter_annotation {
margin-left: -19px;
float: left;
}
.ace_gutter-cell.ace_error, .ace_icon.ace_error, .ace_icon.ace_error_fold, .ace_gutter-cell.ace_security, .ace_icon.ace_security, .ace_icon.ace_security_fold {
background-image: url("./main-1.png");
background-repeat: no-repeat;
background-position: 2px center;
}
.ace_gutter-cell.ace_warning, .ace_icon.ace_warning, .ace_icon.ace_warning_fold {
background-image: url("./main-2.png");
background-repeat: no-repeat;
background-position: 2px center;
}
.ace_gutter-cell.ace_info, .ace_icon.ace_info, .ace_gutter-cell.ace_hint, .ace_icon.ace_hint {
background-image: url("./main-3.png");
background-repeat: no-repeat;
background-position: 2px center;
}
.ace_dark .ace_gutter-cell.ace_info, .ace_dark .ace_icon.ace_info, .ace_dark .ace_gutter-cell.ace_hint, .ace_dark .ace_icon.ace_hint {
background-image: url("./main-4.png");
}
.ace_icon_svg.ace_error {
-webkit-mask-image: url("./main-5.svg");
background-color: crimson;
}
.ace_icon_svg.ace_security {
-webkit-mask-image: url("./main-6.svg");
background-color: crimson;
}
.ace_icon_svg.ace_warning {
-webkit-mask-image: url("./main-7.svg");
background-color: darkorange;
}
.ace_icon_svg.ace_info {
-webkit-mask-image: url("./main-8.svg");
background-color: royalblue;
}
.ace_icon_svg.ace_hint {
-webkit-mask-image: url("./main-9.svg");
background-color: silver;
}
.ace_icon_svg.ace_error_fold {
-webkit-mask-image: url("./main-10.svg");
background-color: crimson;
}
.ace_icon_svg.ace_security_fold {
-webkit-mask-image: url("./main-11.svg");
background-color: crimson;
}
.ace_icon_svg.ace_warning_fold {
-webkit-mask-image: url("./main-12.svg");
background-color: darkorange;
}
.ace_scrollbar {
contain: strict;
position: absolute;
right: 0;
bottom: 0;
z-index: 6;
}
.ace_scrollbar-inner {
position: absolute;
cursor: text;
left: 0;
top: 0;
}
.ace_scrollbar-v{
overflow-x: hidden;
overflow-y: scroll;
top: 0;
}
.ace_scrollbar-h {
overflow-x: scroll;
overflow-y: hidden;
left: 0;
}
.ace_print-margin {
position: absolute;
height: 100%;
}
.ace_text-input {
position: absolute;
z-index: 0;
width: 0.5em;
height: 1em;
opacity: 0;
background: transparent;
-moz-appearance: none;
appearance: none;
border: none;
resize: none;
outline: none;
overflow: hidden;
font: inherit;
padding: 0 1px;
margin: 0 -1px;
contain: strict;
-ms-user-select: text;
-moz-user-select: text;
-webkit-user-select: text;
user-select: text;
/*with `pre-line` chrome inserts &nbsp; instead of space*/
white-space: pre!important;
}
.ace_text-input.ace_composition {
background: transparent;
color: inherit;
z-index: 1000;
opacity: 1;
}
.ace_composition_placeholder { color: transparent }
.ace_composition_marker { 
border-bottom: 1px solid;
position: absolute;
border-radius: 0;
margin-top: 1px;
}
[ace_nocontext=true] {
transform: none!important;
filter: none!important;
clip-path: none!important;
mask : none!important;
contain: none!important;
perspective: none!important;
mix-blend-mode: initial!important;
z-index: auto;
}
.ace_layer {
z-index: 1;
position: absolute;
overflow: hidden;
/* workaround for chrome bug https://github.com/ajaxorg/ace/issues/2312*/
word-wrap: normal;
white-space: pre;
height: 100%;
width: 100%;
box-sizing: border-box;
/* setting pointer-events: auto; on node under the mouse, which changes
during scroll, will break mouse wheel scrolling in Safari */
pointer-events: none;
}
.ace_gutter-layer {
position: relative;
width: auto;
text-align: right;
pointer-events: auto;
height: 1000000px;
contain: style size layout;
}
.ace_text-layer {
font: inherit !important;
position: absolute;
height: 1000000px;
width: 1000000px;
contain: style size layout;
}
.ace_text-layer > .ace_line, .ace_text-layer > .ace_line_group {
contain: style size layout;
position: absolute;
top: 0;
left: 0;
right: 0;
}
.ace_hidpi .ace_text-layer,
.ace_hidpi .ace_gutter-layer,
.ace_hidpi .ace_content,
.ace_hidpi .ace_gutter {
contain: strict;
}
.ace_hidpi .ace_text-layer > .ace_line, 
.ace_hidpi .ace_text-layer > .ace_line_group {
contain: strict;
}
.ace_cjk {
display: inline-block;
text-align: center;
}
.ace_cursor-layer {
z-index: 4;
}
.ace_cursor {
z-index: 4;
position: absolute;
box-sizing: border-box;
border-left: 2px solid;
/* workaround for smooth cursor repaintng whole screen in chrome */
transform: translatez(0);
}
.ace_multiselect .ace_cursor {
border-left-width: 1px;
}
.ace_slim-cursors .ace_cursor {
border-left-width: 1px;
}
.ace_overwrite-cursors .ace_cursor {
border-left-width: 0;
border-bottom: 1px solid;
}
.ace_hidden-cursors .ace_cursor {
opacity: 0.2;
}
.ace_hasPlaceholder .ace_hidden-cursors .ace_cursor {
opacity: 0;
}
.ace_smooth-blinking .ace_cursor {
transition: opacity 0.18s;
}
.ace_animate-blinking .ace_cursor {
animation-duration: 1000ms;
animation-timing-function: step-end;
animation-name: blink-ace-animate;
animation-iteration-count: infinite;
}
.ace_animate-blinking.ace_smooth-blinking .ace_cursor {
animation-duration: 1000ms;
animation-timing-function: ease-in-out;
animation-name: blink-ace-animate-smooth;
}
@keyframes blink-ace-animate {
from, to { opacity: 1; }
60% { opacity: 0; }
}
@keyframes blink-ace-animate-smooth {
from, to { opacity: 1; }
45% { opacity: 1; }
60% { opacity: 0; }
85% { opacity: 0; }
}
.ace_marker-layer .ace_step, .ace_marker-layer .ace_stack {
position: absolute;
z-index: 3;
}
.ace_marker-layer .ace_selection {
position: absolute;
z-index: 5;
}
.ace_marker-layer .ace_bracket {
position: absolute;
z-index: 6;
}
.ace_marker-layer .ace_error_bracket {
position: absolute;
border-bottom: 1px solid #DE5555;
border-radius: 0;
}
.ace_marker-layer .ace_active-line {
position: absolute;
z-index: 2;
}
.ace_marker-layer .ace_selected-word {
position: absolute;
z-index: 4;
box-sizing: border-box;
}
.ace_line .ace_fold {
box-sizing: border-box;
display: inline-block;
height: 11px;
margin-top: -2px;
vertical-align: middle;
background-image:
url("./main-13.png"),
url("./main-14.png");
background-repeat: no-repeat, repeat-x;
background-position: center center, top left;
color: transparent;
border: 1px solid black;
border-radius: 2px;
cursor: pointer;
pointer-events: auto;
}
.ace_dark .ace_fold {
}
.ace_fold:hover{
background-image:
url("./main-15.png"),
url("./main-16.png");
}
.ace_tooltip {
background-color: #f5f5f5;
border: 1px solid gray;
border-radius: 1px;
box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
color: black;
padding: 3px 4px;
position: fixed;
z-index: 999999;
box-sizing: border-box;
cursor: default;
white-space: pre-wrap;
word-wrap: break-word;
line-height: normal;
font-style: normal;
font-weight: normal;
letter-spacing: normal;
pointer-events: none;
overflow: auto;
max-width: min(33em, 66vw);
overscroll-behavior: contain;
}
.ace_tooltip pre {
white-space: pre-wrap;
}
.ace_tooltip.ace_dark {
background-color: #636363;
color: #fff;
}
.ace_tooltip:focus {
outline: 1px solid #5E9ED6;
}
.ace_icon {
display: inline-block;
width: 18px;
vertical-align: top;
}
.ace_icon_svg {
display: inline-block;
width: 12px;
vertical-align: top;
-webkit-mask-repeat: no-repeat;
-webkit-mask-size: 12px;
-webkit-mask-position: center;
}
.ace_folding-enabled > .ace_gutter-cell, .ace_folding-enabled > .ace_gutter-cell_svg-icons {
padding-right: 13px;
}
.ace_fold-widget {
box-sizing: border-box;
margin: 0 -12px 0 1px;
display: none;
width: 11px;
vertical-align: top;
background-image: url("./main-17.png");
background-repeat: no-repeat;
background-position: center;
border-radius: 3px;
border: 1px solid transparent;
cursor: pointer;
}
.ace_folding-enabled .ace_fold-widget {
display: inline-block;   
}
.ace_fold-widget.ace_end {
background-image: url("./main-18.png");
}
.ace_fold-widget.ace_closed {
background-image: url("./main-19.png");
}
.ace_fold-widget:hover {
border: 1px solid rgba(0, 0, 0, 0.3);
background-color: rgba(255, 255, 255, 0.2);
box-shadow: 0 1px 1px rgba(255, 255, 255, 0.7);
}
.ace_fold-widget:active {
border: 1px solid rgba(0, 0, 0, 0.4);
background-color: rgba(0, 0, 0, 0.05);
box-shadow: 0 1px 1px rgba(255, 255, 255, 0.8);
}
/**
* Dark version for fold widgets
*/
.ace_dark .ace_fold-widget {
background-image: url("./main-20.png");
}
.ace_dark .ace_fold-widget.ace_end {
background-image: url("./main-21.png");
}
.ace_dark .ace_fold-widget.ace_closed {
background-image: url("./main-22.png");
}
.ace_dark .ace_fold-widget:hover {
box-shadow: 0 1px 1px rgba(255, 255, 255, 0.2);
background-color: rgba(255, 255, 255, 0.1);
}
.ace_dark .ace_fold-widget:active {
box-shadow: 0 1px 1px rgba(255, 255, 255, 0.2);
}
.ace_inline_button {
border: 1px solid lightgray;
display: inline-block;
margin: -1px 8px;
padding: 0 5px;
pointer-events: auto;
cursor: pointer;
}
.ace_inline_button:hover {
border-color: gray;
background: rgba(200,200,200,0.2);
display: inline-block;
pointer-events: auto;
}
.ace_fold-widget.ace_invalid {
background-color: #FFB4B4;
border-color: #DE5555;
}
.ace_fade-fold-widgets .ace_fold-widget {
transition: opacity 0.4s ease 0.05s;
opacity: 0;
}
.ace_fade-fold-widgets:hover .ace_fold-widget {
transition: opacity 0.05s ease 0.05s;
opacity:1;
}
.ace_underline {
text-decoration: underline;
}
.ace_bold {
font-weight: bold;
}
.ace_nobold .ace_bold {
font-weight: normal;
}
.ace_italic {
font-style: italic;
}
.ace_error-marker {
background-color: rgba(255, 0, 0,0.2);
position: absolute;
z-index: 9;
}
.ace_highlight-marker {
background-color: rgba(255, 255, 0,0.2);
position: absolute;
z-index: 8;
}
.ace_mobile-menu {
position: absolute;
line-height: 1.5;
border-radius: 4px;
-ms-user-select: none;
-moz-user-select: none;
-webkit-user-select: none;
user-select: none;
background: white;
box-shadow: 1px 3px 2px grey;
border: 1px solid #dcdcdc;
color: black;
}
.ace_dark > .ace_mobile-menu {
background: #333;
color: #ccc;
box-shadow: 1px 3px 2px grey;
border: 1px solid #444;
}
.ace_mobile-button {
padding: 2px;
cursor: pointer;
overflow: hidden;
}
.ace_mobile-button:hover {
background-color: #eee;
opacity:1;
}
.ace_mobile-button:active {
background-color: #ddd;
}
.ace_placeholder {
position: relative;
font-family: arial;
transform: scale(0.9);
transform-origin: left;
white-space: pre;
opacity: 0.7;
margin: 0 10px;
z-index: 1;
}
.ace_ghost_text {
opacity: 0.5;
font-style: italic;
}
.ace_ghost_text_container > div {
white-space: pre;
}
.ghost_text_line_wrapped::after {
content: "↩";
position: absolute;
}
.ace_lineWidgetContainer.ace_ghost_text {
margin: 0px 4px
}
.ace_screenreader-only {
position:absolute;
left:-10000px;
top:auto;
width:1px;
height:1px;
overflow:hidden;
}
.ace_hidden_token {
display: none;
}
/*ace-tm*/
.ace-tm .ace_gutter {
background: #f0f0f0;
color: #333;
}
.ace-tm .ace_print-margin {
width: 1px;
background: #e8e8e8;
}
.ace-tm .ace_fold {
background-color: #6B72E6;
}
.ace-tm {
background-color: #FFFFFF;
color: black;
}
.ace-tm .ace_cursor {
color: black;
}
.ace-tm .ace_invisible {
color: rgb(191, 191, 191);
}
.ace-tm .ace_storage,
.ace-tm .ace_keyword {
color: blue;
}
.ace-tm .ace_constant {
color: rgb(197, 6, 11);
}
.ace-tm .ace_constant.ace_buildin {
color: rgb(88, 72, 246);
}
.ace-tm .ace_constant.ace_language {
color: rgb(88, 92, 246);
}
.ace-tm .ace_constant.ace_library {
color: rgb(6, 150, 14);
}
.ace-tm .ace_invalid {
background-color: rgba(255, 0, 0, 0.1);
color: red;
}
.ace-tm .ace_support.ace_function {
color: rgb(60, 76, 114);
}
.ace-tm .ace_support.ace_constant {
color: rgb(6, 150, 14);
}
.ace-tm .ace_support.ace_type,
.ace-tm .ace_support.ace_class {
color: rgb(109, 121, 222);
}
.ace-tm .ace_keyword.ace_operator {
color: rgb(104, 118, 135);
}
.ace-tm .ace_string {
color: rgb(3, 106, 7);
}
.ace-tm .ace_comment {
color: rgb(76, 136, 107);
}
.ace-tm .ace_comment.ace_doc {
color: rgb(0, 102, 255);
}
.ace-tm .ace_comment.ace_doc.ace_tag {
color: rgb(128, 159, 191);
}
.ace-tm .ace_constant.ace_numeric {
color: rgb(0, 0, 205);
}
.ace-tm .ace_variable {
color: rgb(49, 132, 149);
}
.ace-tm .ace_xml-pe {
color: rgb(104, 104, 91);
}
.ace-tm .ace_entity.ace_name.ace_function {
color: #0000A2;
}
.ace-tm .ace_heading {
color: rgb(12, 7, 255);
}
.ace-tm .ace_list {
color:rgb(185, 6, 144);
}
.ace-tm .ace_meta.ace_tag {
color:rgb(0, 22, 142);
}
.ace-tm .ace_string.ace_regex {
color: rgb(255, 0, 0)
}
.ace-tm .ace_marker-layer .ace_selection {
background: rgb(181, 213, 255);
}
.ace-tm.ace_multiselect .ace_selection.ace_start {
box-shadow: 0 0 3px 0px white;
}
.ace-tm .ace_marker-layer .ace_step {
background: rgb(252, 255, 0);
}
.ace-tm .ace_marker-layer .ace_stack {
background: rgb(164, 229, 101);
}
.ace-tm .ace_marker-layer .ace_bracket {
margin: -1px 0 0 -1px;
border: 1px solid rgb(192, 192, 192);
}
.ace-tm .ace_marker-layer .ace_active-line {
background: rgba(0, 0, 0, 0.07);
}
.ace-tm .ace_gutter-active-line {
background-color : #dcdcdc;
}
.ace-tm .ace_marker-layer .ace_selected-word {
background: rgb(250, 250, 255);
border: 1px solid rgb(200, 200, 250);
}
.ace-tm .ace_indent-guide {
background: url("./main-23.png") right repeat-y;
}
.ace-tm .ace_indent-guide-active {
background: url("./main-24.png") right repeat-y;
}
/*error_marker.css*/
.error_widget_wrapper {
background: inherit;
color: inherit;
border:none
}
.error_widget {
border-top: solid 2px;
border-bottom: solid 2px;
margin: 5px 0;
padding: 10px 40px;
white-space: pre-wrap;
}
.error_widget.ace_error, .error_widget_arrow.ace_error{
border-color: #ff5a5a
}
.error_widget.ace_warning, .error_widget_arrow.ace_warning{
border-color: #F1D817
}
.error_widget.ace_info, .error_widget_arrow.ace_info{
border-color: #5a5a5a
}
.error_widget.ace_ok, .error_widget_arrow.ace_ok{
border-color: #5aaa5a
}
.error_widget_arrow {
position: absolute;
border: solid 5px;
border-top-color: transparent!important;
border-right-color: transparent!important;
border-left-color: transparent!important;
top: -5px;
}
/*codelense.css*/
.ace_codeLens {
position: absolute;
color: #aaa;
font-size: 88%;
background: inherit;
width: 100%;
display: flex;
align-items: flex-end;
pointer-events: none;
}
.ace_codeLens > a {
cursor: pointer;
pointer-events: auto;
}
.ace_codeLens > a:hover {
color: #0000ff;
text-decoration: underline;
}
.ace_dark > .ace_codeLens > a:hover {
color: #4e94ce;
}
/*commandbar.css*/
.ace_tooltip.command_bar_tooltip_wrapper {
padding: 0;
}
.ace_tooltip .command_bar_tooltip {
padding: 1px 5px;
display: flex;
pointer-events: auto;
}
.ace_tooltip .command_bar_tooltip.tooltip_more_options {
padding: 1px;
flex-direction: column;
}
div.command_bar_tooltip_button {
display: inline-flex;
cursor: pointer;
margin: 1px;
border-radius: 2px;
padding: 2px 5px;
align-items: center;
}
div.command_bar_tooltip_button.ace_selected,
div.command_bar_tooltip_button:hover:not(.ace_disabled) {
background-color: rgba(0, 0, 0, 0.1);
}
div.command_bar_tooltip_button.ace_disabled {
color: #777;
pointer-events: none;
}
div.command_bar_tooltip_button .ace_icon_svg {
height: 12px;
background-color: #000;
}
div.command_bar_tooltip_button.ace_disabled .ace_icon_svg {
background-color: #777;
}
.command_bar_tooltip.tooltip_more_options .command_bar_tooltip_button {
display: flex;
}
.command_bar_tooltip.command_bar_button_value {
display: none;
}
.command_bar_tooltip.tooltip_more_options .command_bar_button_value {
display: inline-block;
width: 12px;
}
.command_bar_button_caption {
display: inline-block;
}
.command_bar_keybinding {
margin: 0 2px;
display: inline-block;
font-size: 8px;
}
.command_bar_tooltip.tooltip_more_options .command_bar_keybinding {
margin-left: auto;
}
.command_bar_keybinding div {
display: inline-block;
min-width: 8px;
padding: 2px;
margin: 0 1px;
border-radius: 2px;
background-color: #ccc;
text-align: center;
}
.ace_dark.ace_tooltip .command_bar_tooltip {
background-color: #373737;
color: #eee;
}
.ace_dark div.command_bar_tooltip_button.ace_disabled {
color: #979797;
}
.ace_dark div.command_bar_tooltip_button.ace_selected,
.ace_dark div.command_bar_tooltip_button:hover:not(.ace_disabled) {
background-color: rgba(255, 255, 255, 0.1);
}
.ace_dark div.command_bar_tooltip_button .ace_icon_svg {
background-color: #eee;
}
.ace_dark div.command_bar_tooltip_button.ace_disabled .ace_icon_svg {
background-color: #979797;
}
.ace_dark .command_bar_tooltip_button.ace_disabled {
color: #979797;
}
.ace_dark .command_bar_keybinding div {
background-color: #575757;
}
.ace_checkmark::before {
content: '✓';
}
/*snippets.css*/
.ace_snippet-marker {
-moz-box-sizing: border-box;
box-sizing: border-box;
background: rgba(194, 193, 208, 0.09);
border: 1px dotted rgba(211, 208, 235, 0.62);
position: absolute;
}
/*autocompletion.css*/
.ace_editor.ace_autocomplete .ace_marker-layer .ace_active-line {
background-color: #CAD6FA;
z-index: 1;
}
.ace_dark.ace_editor.ace_autocomplete .ace_marker-layer .ace_active-line {
background-color: #3a674e;
}
.ace_editor.ace_autocomplete .ace_line-hover {
border: 1px solid #abbffe;
margin-top: -1px;
background: rgba(233,233,253,0.4);
position: absolute;
z-index: 2;
}
.ace_dark.ace_editor.ace_autocomplete .ace_line-hover {
border: 1px solid rgba(109, 150, 13, 0.8);
background: rgba(58, 103, 78, 0.62);
}
.ace_completion-meta {
opacity: 0.5;
margin-left: 0.9em;
}
.ace_completion-message {
margin-left: 0.9em;
color: blue;
}
.ace_editor.ace_autocomplete .ace_completion-highlight{
color: #2d69c7;
}
.ace_dark.ace_editor.ace_autocomplete .ace_completion-highlight{
color: #93ca12;
}
.ace_editor.ace_autocomplete {
width: 300px;
z-index: 200000;
border: 1px lightgray solid;
position: fixed;
box-shadow: 2px 3px 5px rgba(0,0,0,.2);
line-height: 1.4;
background: #fefefe;
color: #111;
}
.ace_dark.ace_editor.ace_autocomplete {
border: 1px #484747 solid;
box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.51);
line-height: 1.4;
background: #25282c;
color: #c1c1c1;
}
.ace_autocomplete .ace_text-layer  {
width: calc(100% - 8px);
}
.ace_autocomplete .ace_line {
display: flex;
align-items: center;
}
.ace_autocomplete .ace_line > * {
min-width: 0;
flex: 0 0 auto;
}
.ace_autocomplete .ace_line .ace_ {
flex: 0 1 auto;
overflow: hidden;
text-overflow: ellipsis;
}
.ace_autocomplete .ace_completion-spacer {
flex: 1;
}
.ace_autocomplete.ace_loading:after  {
content: "";
position: absolute;
top: 0px;
height: 2px;
width: 8%;
background: blue;
z-index: 100;
animation: ace_progress 3s infinite linear;
animation-delay: 300ms;
transform: translateX(-100%) scaleX(1);
}
@keyframes ace_progress {
0% { transform: translateX(-100%) scaleX(1) }
50% { transform: translateX(625%) scaleX(2) } 
100% { transform: translateX(1500%) scaleX(3) } 
}
@media (prefers-reduced-motion) {
.ace_autocomplete.ace_loading:after {
transform: translateX(625%) scaleX(2);
animation: none;
}
}
/*inlineautocomplete.css*/
.ace_icon_svg.ace_arrow,
.ace_icon_svg.ace_arrow_rotated {
-webkit-mask-image: url("./main-25.svg");
}
.ace_icon_svg.ace_arrow_rotated {
transform: rotate(180deg);
}
div.command_bar_tooltip_button.completion_position {
padding: 0;
}
/*settings_menu.css*/
#ace_settingsmenu, #kbshortcutmenu {
background-color: #F7F7F7;
color: black;
box-shadow: -5px 4px 5px rgba(126, 126, 126, 0.55);
padding: 1em 0.5em 2em 1em;
overflow: auto;
position: absolute;
margin: 0;
bottom: 0;
right: 0;
top: 0;
z-index: 9991;
cursor: default;
}
.ace_dark #ace_settingsmenu, .ace_dark #kbshortcutmenu {
box-shadow: -20px 10px 25px rgba(126, 126, 126, 0.25);
background-color: rgba(255, 255, 255, 0.6);
color: black;
}
.ace_optionsMenuEntry:hover {
background-color: rgba(100, 100, 100, 0.1);
transition: all 0.3s
}
.ace_closeButton {
background: rgba(245, 146, 146, 0.5);
border: 1px solid #F48A8A;
border-radius: 50%;
padding: 7px;
position: absolute;
right: -8px;
top: -8px;
z-index: 100000;
}
.ace_closeButton{
background: rgba(245, 146, 146, 0.9);
}
.ace_optionsMenuKey {
color: darkslateblue;
font-weight: bold;
}
.ace_optionsMenuCommand {
color: darkcyan;
font-weight: normal;
}
.ace_optionsMenuEntry input, .ace_optionsMenuEntry button {
vertical-align: middle;
}
.ace_optionsMenuEntry button[ace_selected_button=true] {
background: #e7e7e7;
box-shadow: 1px 0px 2px 0px #adadad inset;
border-color: #adadad;
}
.ace_optionsMenuEntry button {
background: white;
border: 1px solid lightgray;
margin: 0px;
}
.ace_optionsMenuEntry button:hover{
background: #f0f0f0;
}
/*promtp.css*/
.ace_prompt_container {
max-width: 603px;
width: 100%;
margin: 20px auto;
padding: 3px;
background: white;
border-radius: 2px;
box-shadow: 0px 2px 3px 0px #555;
}
/*ace_searchbox*/
/* ------------------------------------------------------------------------------------------
* Editor Search Form
* --------------------------------------------------------------------------------------- */
.ace_search {
background-color: #ddd;
color: #666;
border: 1px solid #cbcbcb;
border-top: 0 none;
overflow: hidden;
margin: 0;
padding: 4px 6px 0 4px;
position: absolute;
top: 0;
z-index: 99;
white-space: normal;
}
.ace_search.left {
border-left: 0 none;
border-radius: 0px 0px 5px 0px;
left: 0;
}
.ace_search.right {
border-radius: 0px 0px 0px 5px;
border-right: 0 none;
right: 0;
}
.ace_search_form, .ace_replace_form {
margin: 0 20px 4px 0;
overflow: hidden;
line-height: 1.9;
}
.ace_replace_form {
margin-right: 0;
}
.ace_search_form.ace_nomatch {
outline: 1px solid red;
}
.ace_search_field {
border-radius: 3px 0 0 3px;
background-color: white;
color: black;
border: 1px solid #cbcbcb;
border-right: 0 none;
outline: 0;
padding: 0;
font-size: inherit;
margin: 0;
line-height: inherit;
padding: 0 6px;
min-width: 17em;
vertical-align: top;
min-height: 1.8em;
box-sizing: content-box;
}
.ace_searchbtn {
border: 1px solid #cbcbcb;
line-height: inherit;
display: inline-block;
padding: 0 6px;
background: #fff;
border-right: 0 none;
border-left: 1px solid #dcdcdc;
cursor: pointer;
margin: 0;
position: relative;
color: #666;
}
.ace_searchbtn:last-child {
border-radius: 0 3px 3px 0;
border-right: 1px solid #cbcbcb;
}
.ace_searchbtn:disabled {
background: none;
cursor: default;
}
.ace_searchbtn:hover {
background-color: #eef1f6;
}
.ace_searchbtn.prev, .ace_searchbtn.next {
padding: 0px 0.7em
}
.ace_searchbtn.prev:after, .ace_searchbtn.next:after {
content: "";
border: solid 2px #888;
width: 0.5em;
height: 0.5em;
border-width:  2px 0 0 2px;
display:inline-block;
transform: rotate(-45deg);
}
.ace_searchbtn.next:after {
border-width: 0 2px 2px 0 ;
}
.ace_searchbtn_close {
background: url("./main-26.png") no-repeat 50% 0;
border-radius: 50%;
border: 0 none;
color: #656565;
cursor: pointer;
font: 16px/16px Arial;
padding: 0;
height: 14px;
width: 14px;
top: 9px;
right: 7px;
position: absolute;
}
.ace_searchbtn_close:hover {
background-color: #656565;
background-position: 50% 100%;
color: white;
}
.ace_button {
margin-left: 2px;
cursor: pointer;
-webkit-user-select: none;
-moz-user-select: none;
-o-user-select: none;
-ms-user-select: none;
user-select: none;
overflow: hidden;
opacity: 0.7;
border: 1px solid rgba(100,100,100,0.23);
padding: 1px;
box-sizing:    border-box!important;
color: black;
}
.ace_button:hover {
background-color: #eee;
opacity:1;
}
.ace_button:active {
background-color: #ddd;
}
.ace_button.checked {
border-color: #3399ff;
opacity:1;
}
.ace_search_options{
margin-bottom: 3px;
text-align: right;
-webkit-user-select: none;
-moz-user-select: none;
-o-user-select: none;
-ms-user-select: none;
user-select: none;
clear: both;
}
.ace_search_counter {
float: left;
font-family: arial;
padding: 0 8px;
}
/*incremental-occur-highlighting*/
.ace_occur-highlight {
border-radius: 4px;
background-color: rgba(87, 255, 8, 0.25);
position: absolute;
z-index: 4;
box-sizing: border-box;
box-shadow: 0 0 4px rgb(91, 255, 50);
}
.ace_dark .ace_occur-highlight {
background-color: rgb(80, 140, 85);
box-shadow: 0 0 4px rgb(60, 120, 70);
}
/*incremental-search-highlighting*/
.ace_marker-layer .ace_isearch-result {
position: absolute;
z-index: 6;
box-sizing: border-box;
}
div.ace_isearch-result {
border-radius: 4px;
background-color: rgba(255, 200, 0, 0.5);
box-shadow: 0 0 4px rgb(255, 200, 0);
}
.ace_dark div.ace_isearch-result {
background-color: rgb(100, 110, 160);
box-shadow: 0 0 4px rgb(80, 90, 140);
}
/*emacsMode*/
.emacs-mode .ace_cursor{
border: 1px rgba(50,250,50,0.8) solid!important;
box-sizing: border-box!important;
background-color: rgba(0,250,0,0.9);
opacity: 0.5;
}
.emacs-mode .ace_hidden-cursors .ace_cursor{
opacity: 1;
background-color: transparent;
}
.emacs-mode .ace_overwrite-cursors .ace_cursor {
opacity: 1;
background-color: transparent;
border-width: 0 0 2px 2px !important;
}
.emacs-mode .ace_text-layer {
z-index: 4
}
.emacs-mode .ace_cursor-layer {
z-index: 2
}
/*vimMode*/
.normal-mode .ace_cursor{
border: none;
background-color: rgba(255,0,0,0.5);
}
.normal-mode .ace_hidden-cursors .ace_cursor{
background-color: transparent;
border: 1px solid red;
opacity: 0.7
}
.ace_dialog {
position: absolute;
left: 0; right: 0;
background: inherit;
z-index: 15;
padding: .1em .8em;
overflow: hidden;
color: inherit;
}
.ace_dialog-top {
border-bottom: 1px solid #444;
top: 0;
}
.ace_dialog-bottom {
border-top: 1px solid #444;
bottom: 0;
}
.ace_dialog input {
border: none;
outline: none;
background: transparent;
width: 20em;
color: inherit;
font-family: monospace;
}